import * as React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";

const miscellaneous = {
  name: "Miscellaneous",
  releaseDate: "n/a",
  imgSrc: "images/tbd.jpg",
  imgAlt: "to be determined",
  comments: `These are songs that aren't available on any of the real releases.

Clique War was recored in May 2004, but we didn't finish mixing it until February 2005. We just really suck. It was recorded on Raland's computer, with his OEM soundcard, in my garage. Eventually it was mixed, with my OEM soundcard, in my kitchen.
  
Facing Up was recorded in October 2003, with a process similar to Clique War. Except Facing Up was actually done first.
  
Whispers was recorded centuries ago, with a significantly different line up. It is also the only surviving example of guitar solos in our mucis.`,
  songs: [
    {
      name: "Clique War",
      url: "/audio/Dichotic-Miscellaneous-1-CliqueWar.mp3",
    },
    {
      name: "Facing Up, Forced Down",
      url: "/audio/Dichotic-Miscellaneous-2-FacingUpForcedDown.mp3",
    },
    {
      name: "Whispers of Immortality",
      url: "/audio/Dichotic-Miscellaneous-3-WhispersOfImmortality.mp3",
    },
  ],
};

const tltl = {
  name: "Too Little, Too Late",
  releaseDate: "tbd",
  imgSrc: "",
  imgAlt: "too be determined",
  comments:
    "Drums and bass were recorded in the summer of 2001. Vocals were done around December of the same year. Guitars... we'll see.",
  songs: [
    { name: "Panic Device", url: "" },
    { name: "Failsafe", url: "" },
    { name: "Worst Case Scenario", url: "" },
    { name: "Fat Farm", url: "" },
    { name: "Running Joke", url: "" },
    { name: "Cease", url: "" },
    { name: "Opening Eyes ", url: "" },
  ],
};

const sosea = {
  name: "Seen One, Seen'Em All",
  releaseDate: "March 2003",
  imgSrc: "/images/cover_sosea_155x155.jpg",
  comments: `These three songs were written and recorded during three brief "reunions". ("reunion" reads: all of us in the same town at the same time).

Each song was put together in a day, then recorded the next. Considering it took us months to write songs when we were "active"... pretty speedy, eh?

The idea was to make the EP available at the March 8th "reunion" show, which, of course, was cancelled. The EP lives on, however, and it comes with a t-shirt!`,
  songs: [
    {
      name: "None the Wiser",
      url: "/audio/Dichotic-SeenOneSeenEmAll-1-NoneTheWiser.mp3",
    },
    {
      name: "Scratched Out",
      url: "/audio/Dichotic-SeenOneSeenEmAll-2-ScratchedOut.mp3",
    },
    { name: "Patriotic" },
  ],
};

const lcd = {
  name: "Lowest Common Denominator",
  releaseDate: "October 1999",
  imgSrc: "/images/cover_lcd_155x155.jpg",
  comments: `We recorded and mixed this one in a couple of days at Deep9 studios. By the end of it, ears were fried and not too... picky.

Includes some pre-historic unreleased music, video and other assorted crap.`,
  songs: [
    {
      name: "spilling;",
      url: "/audio/Dichotic-LowestCommonDenominator-1-Spilling.mp3",
    },
    { name: "Unholy Exhumation", url: "" },
    {
      name: "Fisting",
      url: "/audio/Dichotic-LowestCommonDenominator-3-Fisting.mp3",
    },
    { name: "Precise", url: "" },
    { name: "Failed Expectations", url: "" },
    { name: "Smothered", url: "" },
    { name: "Cyclical Retardation", url: "" },
    { name: "(sic)", url: "" },
    { name: "Dead Simple", url: "" },
  ],
};

const cid = {
  name: "Collapse Into Despair",
  releaseDate: "February 1998",
  imgSrc: "/images/cover_cid_155x155.jpg",
  fullDownload: {
    name: "Download full album",
    url: "/audio/Dichotic-CollapseIntoDespair.zip",
  },
  comments: `Originally we recorded at a community college. We weren't happy with the guitar sound, so we re-recorded them in various places.

The bass might have been re-recorded too, I can't remember.

I don't remember anything about mixing and mastering.

No longer available on CD, so download it.`,
  songs: [
    {
      name: "Solely On Opposites",
      url: "/audio/Dichotic-CollapseIntoDespair-1-SolelyOnOpposites.mp3",
    },
    {
      name: "God Complex",
      url: "/audio/Dichotic-CollapseIntoDespair-2-GodComplex.mp3",
    },
    {
      name: "Touching the Timeless",
      url: "/audio/Dichotic-CollapseIntoDespair-3-TouchingTheTimeless.mp3",
    },
    {
      name: "Heed to Instincts",
      url: "/audio/Dichotic-CollapseIntoDespair-4-HeedToInstincts.mp3",
    },
    {
      name: "Out of Spite",
      url: "/audio/Dichotic-CollapseIntoDespair-5-OutOfSpite.mp3",
    },
    {
      name: "Love Stained Splatter",
      url: "/audio/Dichotic-CollapseIntoDespair-6-LoveStainedSplatter.mp3",
    },
    {
      name: "Unholy Exhumation",
      url: "/audio/Dichotic-CollapseIntoDespair-7-UnholyExhumation.mp3",
    },
  ],
};

const albums = [miscellaneous, tltl, sosea, lcd, cid];

const Album = ({ album, addTopMargin }) => (
  <>
    <tr>
      <td colSpan={3}>
        <div style={{ marginTop: addTopMargin ? 30 : 0 }}>
          <h3 className="albumTitle">{album.name}</h3>
          <h4 className="albumReleaseDate">released: {album.releaseDate}</h4>
        </div>
      </td>
    </tr>
    <tr>
      <td className="cell-format" valign="top">
        <img
          alt={album.imgAlt || album.name}
          title={album.imgAlt || album.name}
          width="155"
          height="155"
          src={album.imgSrc}
        />
      </td>
      <td className="cell-format" width="300" valign="top">
        <ol className="albumTrackList">
          {album.songs.map((s) => (
            <li key={s.name}>
              {s.url ? (
                <a href={s.url} download={true}>
                  {s.name}
                </a>
              ) : (
                s.name
              )}
            </li>
          ))}
        </ol>
        {album.fullDownload && (
          <a
            className="fullAlbumDownload"
            href={album.fullDownload.url}
            download={true}
          >
            {album.fullDownload.name}
          </a>
        )}
      </td>
      <td className="cell-format" valign="top">
        {album.comments}
      </td>
    </tr>
  </>
);

const Discography = () => (
  <Layout colSpan={3}>
    <Seo title="discography" />
    {albums.map((a, i) => (
      <Album album={a} addTopMargin={i !== 0} key={a.name} />
    ))}
  </Layout>
);

export default Discography;
